import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslationQuery } from 'api/translations';
import { GenerationJob } from 'components/fragments/PDFExport/context/PDFExportContext';
import { AlertTypes } from 'components/shared';
import { isScannerApp, messageToApp } from 'helpers/app';
import { formatTranslation } from 'helpers/stringHelpers';
import { appActions } from 'store/actions/appActions';

export const usePdfGeneration = () => {
	const { data: translations } = useTranslationQuery();
	const dispatch = useDispatch();
	const [generationJobsQueue, setGenerationJobsQueue] = React.useState<GenerationJob[]>([]);

	const addJob = (job: GenerationJob) => {
		setGenerationJobsQueue((prev) => [...prev, job]);
	};

	const updateJob = (jobId: string, update: Partial<GenerationJob>) => {
		setGenerationJobsQueue((prev) => prev.map((job) => (job.id === jobId ? { ...job, ...update } : job)));
	};

	const removeFinishedJobs = () => {
		setGenerationJobsQueue((prev) => {
			if (prev.filter((job) => job.finished).length === 0) {
				return prev;
			}

			return prev.filter((job) => !job.finished);
		});
	};

	const handlePdfGeneration = async (
		jobId: string,
		fileName: string,
		generationFunction: () => Promise<{ url: string; blob: string }>,
	) => {
		addJob({
			id: jobId,
			title: fileName,
			finished: false,
			startTime: Date.now(),
			loading: true,
		});

		try {
			updateJob(jobId, { loading: true, startTime: Date.now() });

			const { url, blob } = await generationFunction();

			updateJob(jobId, { url, blob, loading: false, finished: true });
		} catch (error) {
			console.error(error);
			updateJob(jobId, { error: JSON.stringify(error), loading: false, finished: false });
		}
	};

	const handleJobCompleted = React.useCallback(() => {
		generationJobsQueue.forEach((job) => {
			if (job.finished) {
				if (job.error) {
					dispatch(
						appActions.addNotification({
							children: (
								<p>
									{formatTranslation(translations?.shared.exports.pdfGenerationFailed, {
										0: job.title,
									})}
								</p>
							),
							type: AlertTypes.DANGER,
						}),
					);

					return;
				}

				if (isScannerApp) {
					if (!job.blob) {
						return;
					}

					messageToApp({
						type: 'downloadFile',
						blobInBase64: job.blob,
						downloadTitle: job.title,
					});
				} else {
					const a = document.createElement('a');
					a.href = job.url || '';
					a.download = job.title;
					a.click();
				}

				dispatch(
					appActions.addNotification({
						children: (
							<p>
								{formatTranslation(translations?.shared.exports.pdfGenerationSuccess, {
									0: job.title,
								})}
							</p>
						),
						type: AlertTypes.SUCCESS,
					}),
				);
			}
		});

		removeFinishedJobs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generationJobsQueue, dispatch]);

	React.useEffect(() => {
		handleJobCompleted();
	}, [generationJobsQueue, handleJobCompleted]);

	return { handlePdfGeneration, generationJobsQueue };
};
