import React from 'react';
import * as Comlink from 'comlink';
import { uniqueId } from 'lodash';
import { usePdfGeneration } from 'components/fragments/PDFExport/hooks/usePdfGeneration';
import { PdfWorkerType } from 'components/fragments/PDFExport/workers/pdf.worker';
import PdfWorker from 'components/fragments/PDFExport/workers/pdf.worker?worker';
import { PDFDownloadResponse, PDFDownloadWithSummaryResponse, TranslationsWebsite } from 'generated/data-contracts';

const pdfWorker = new PdfWorker();
const worker = Comlink.wrap<PdfWorkerType>(pdfWorker);

interface GeneratePdfWithMediaPayload {
	title: string;
	id?: string;
	data: PDFDownloadResponse;
	translations: TranslationsWebsite;
}

interface GeneratePdfInListPayload {
	title: string;
	id?: string;
	data: PDFDownloadWithSummaryResponse;
	translations: TranslationsWebsite;
}

export interface GenerationJob {
	id: string;
	title: string;
	loading: boolean;
	startTime: number;
	finished: boolean;
	error?: string;
	url?: string;
	blob?: string;
}

interface UsePDFExportContextReturnType {
	generatePdfWithMedia: (payload: GeneratePdfWithMediaPayload) => Promise<void>;
	generatePdfInList: (payload: GeneratePdfInListPayload) => Promise<void>;
	generationJobsQueue: GenerationJob[];
}

const usePDFExportContextState = (): UsePDFExportContextReturnType => {
	const { handlePdfGeneration, generationJobsQueue } = usePdfGeneration();

	const generatePdfWithMedia = React.useCallback(
		async ({ id, title, data, translations }: GeneratePdfWithMediaPayload) => {
			const jobId = id ?? uniqueId('pdf-');
			const fileName = `${title}.pdf`;

			await handlePdfGeneration(jobId, fileName, () => {
				return worker.generatePdfWithMedia({ payload: data, title, translations });
			});
		},
		[handlePdfGeneration],
	);

	const generatePdfInList = React.useCallback(
		async ({ id, title, data, translations }: GeneratePdfInListPayload) => {
			const jobId = id ?? uniqueId('pdf-');
			const fileName = `${title}.pdf`;

			await handlePdfGeneration(jobId, fileName, () => {
				return worker.generatePdfInList({ payload: data, title, translations });
			});
		},
		[handlePdfGeneration],
	);

	return {
		generatePdfWithMedia,
		generatePdfInList,
		generationJobsQueue,
	};
};

const PDFExportContextContext = React.createContext<UsePDFExportContextReturnType | null>(null);

export const PDFExportContextProvider: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
	const value = usePDFExportContextState();
	return <PDFExportContextContext.Provider value={value}>{children}</PDFExportContextContext.Provider>;
};

export const usePDFExportContext = (): UsePDFExportContextReturnType => {
	const context = React.useContext(PDFExportContextContext);

	if (!context) {
		throw new Error('usePDFExportContext must be used within a PDFExportContextProvider');
	}

	return context;
};
