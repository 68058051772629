import React, { FC, useState, Dispatch, SetStateAction, ChangeEvent } from 'react';
import classNames from 'classnames';
import { formatTranslation, useEventListener, useDebounce } from 'helpers';
import { useLayoutQuery } from 'api/layout';
import { useShiptoSearchQuery, useShiptoSelectedListQuery } from 'api/shipTo';
import { useTranslationQuery } from 'api/translations';
import { InputCheckbox, SearchBar, SelectedShipTos } from 'components/shared';
import { ShipToInfoResponse, Role } from 'generated/data-contracts';
import { useInputDebounce } from 'helpers/useInputDebounce';
import { useViewportHeight } from 'helpers/useViewportSizeHeight';
import styles from './AccountSelectorBody.module.scss';

interface AccountSelectorBodyProps {
	isCreateBasketView?: boolean;
	onToggleClick: () => void;
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	selectedShipTos: ShipToInfoResponse[];
	setSelectedShipTos: Dispatch<SetStateAction<ShipToInfoResponse[]>>;
	handleSubmit: (evt) => void;
	setFilterList: React.Dispatch<React.SetStateAction<ShipToInfoResponse[]>>;
	isSelectedShipTosExpanded: boolean;
	formId: string;
	parentRef?: React.RefObject<HTMLElement>;
	page?: number;
}

const AccountSelectorBody: FC<AccountSelectorBodyProps> = ({
	selectedShipTos,
	setSelectedShipTos,
	handleSubmit,
	handleChange,
	onToggleClick,
	setFilterList,
	isCreateBasketView,
	isSelectedShipTosExpanded,
	formId,
	parentRef,
	page,
}) => {
	const { data: translations } = useTranslationQuery();
	const { isSmallScreenSize } = useViewportHeight();
	const { data: layout } = useLayoutQuery();

	const [filteredList, setFilteredList] = useState<ShipToInfoResponse[]>([]);
	const [searchText, setSearchText] = useState('');
	const debouncedInput = useInputDebounce(searchText, 300);
	const [currentPage, setCurrentPage] = useState(page ?? 1);
	const [isEnabled, setIsEnabled] = React.useState<boolean>(true);

	const ulRef = React.useRef<HTMLUListElement>(null);
	const divRef = React.useRef<HTMLDivElement>(null);

	// get saved shiptos
	const {
		data: savedShipTos,
		isFetching: isFetchingSavedShipTos,
		isError: isErrorSavedShipTos,
		isPreviousData: isPreviousDataSavedShipTos,
	} = useShiptoSelectedListQuery();

	React.useEffect(() => {
		setFilterList(filteredList);
	}, [filteredList, setFilterList]);

	// get shiptos
	const { isError, data, isFetching, isPreviousData } = useShiptoSearchQuery({
		phrase: debouncedInput,
		page: currentPage,
		sortResult: isCreateBasketView ? false : true,
		isEnabled,
	});

	const isLoadingOrFetching = () => {
		if (isFetching && !isPreviousData) return true;
		if (isFetchingSavedShipTos && !isPreviousDataSavedShipTos) return true;
		return false;
	};

	const isErrorOrErrorSavedShipTos = isError || isErrorSavedShipTos;

	const DefaultPageSize = 36;
	const totalNumberShipTos = data?.totalCount || 1;
	const lastPage = Math.ceil(totalNumberShipTos / DefaultPageSize);

	React.useEffect(() => {
		if (isCreateBasketView) {
			// clear selected shipto
			setSelectedShipTos([]);
		} else if (savedShipTos) {
			setSelectedShipTos([...savedShipTos.shipTos]);
		}
	}, [setSelectedShipTos, isCreateBasketView, savedShipTos]);

	React.useEffect(() => {
		if (isFetching) {
			return;
		}

		if (!isFetching && data) {
			if (currentPage > 1) {
				// add new paging
				setFilteredList((prevData) => [...prevData, ...data.shipTos]);
			} else {
				setFilteredList([...data.shipTos]);

				// reset scrolling if page 1
				const listElement = ulRef.current;
				if (listElement) listElement.scrollTop = 0;
			}
		}
	}, [currentPage, isPreviousData, isFetching, data]);

	const onSubmit = (e): void => {
		setIsEnabled(false);
		handleSubmit(e);
	};

	const handleScroll = useDebounce(async (): Promise<void> => {
		const listElement = isSmallScreenSize ? (parentRef ? parentRef.current : divRef.current) : ulRef.current;

		if (isFetching) {
			return;
		}
		if (!listElement || listElement.scrollTop + listElement.clientHeight < listElement.scrollHeight - 250) {
			return;
		}

		if (currentPage < lastPage) {
			// next page
			setCurrentPage((prevPage) => prevPage + 1);
		}
	}, 300);

	useEventListener('scroll', handleScroll, isSmallScreenSize ? (parentRef ? parentRef : divRef) : ulRef);

	const handleSelectAll = (): void => {
		if (selectedShipTos.length === 0) {
			// set selected list = paginglist - expands as user scrolls

			setSelectedShipTos([...filteredList]);
		} else {
			setSelectedShipTos([]);
		}
	};

	const handleSearch = (event): void => {
		const { value } = event.target;

		setSearchText(value);
		setCurrentPage(1);
	};

	const clearSearch = (): void => {
		setSearchText('');
		setCurrentPage(1);
	};

	const handleKeyDown = (event): void => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	};

	// Function to display appropriate message when no accounts are found
	const displayNoAccountsMessage = (): string | undefined => {
		// If there's a search text, return the "no accounts for search" message
		if (searchText) {
			return translations?.accountSelector.noAccountsForSearch;
		}

		// If the user is a salesperson, return a specific message with a hint to edit accounts
		if (layout?.user?.role === Role.SalesPerson) {
			return formatTranslation(translations?.accountSelector.noLinkedAccountsForSalesPerson, {
				0: translations?.accountSelector.editAccounts.manageYourAccounts,
			});
		}

		// Default case: return the general "no accounts for search" message
		return translations?.accountSelector.noAccountsForSearch;
	};

	return (
		<div
			className={classNames(styles.body, {
				[styles.hasVisibleOverflow]: parentRef && isSmallScreenSize,
			})}
		>
			<form id={formId} className={styles.form} onSubmit={onSubmit}>
				<div
					className={classNames(styles.searchContainer, {
						[styles.hasScrollOverflow]: !parentRef && isSmallScreenSize,
						[styles.isHidden]: isSelectedShipTosExpanded,
					})}
					ref={divRef}
				>
					<div className={classNames(styles.topSection, { [styles.isExpanded]: isSelectedShipTosExpanded })}>
						<SearchBar
							classNameButtons={styles.searchBarButtons}
							classNameInput={styles.searchBarInput}
							iconSize="md"
							id="basketfindaccount"
							buttonType="button"
							onChange={handleSearch}
							onClear={clearSearch}
							onKeyDown={handleKeyDown}
							placeholder="Search accounts"
							value={searchText}
						/>
						<InputCheckbox
							className={styles.selectAll}
							checkBoxClassName={styles.checkBox}
							name="selectAll"
							onChange={handleSelectAll}
							checked={selectedShipTos.length > 0}
							partiallyChecked={filteredList?.length !== selectedShipTos.length}
							label={
								selectedShipTos.length === 0
									? `${translations?.shared.selectAll} (${filteredList.length})`
									: `${translations?.shared.deselectAll} (${selectedShipTos?.length})`
							}
						/>
					</div>

					<ul
						className={classNames(styles.list, {
							[styles.isExpanded]: isSelectedShipTosExpanded,
						})}
						ref={ulRef}
					>
						{isLoadingOrFetching() ? (
							<div className={styles.spinnerContainer}>
								<span className="u-spinner"></span>
							</div>
						) : isErrorOrErrorSavedShipTos ? (
							<div>{translations?.accountSelector.showingAccountsError}</div>
						) : filteredList.length > 0 ? (
							filteredList.map((item, index) => {
								const isSelected = selectedShipTos.some((account) => account.id === item.id);

								return (
									<li
										key={`${item.id}-${index}`}
										className={classNames(styles.shipToItem, {
											[styles.selected]: isSelected,
										})}
									>
										<InputCheckbox
											onChange={handleChange}
											checkBoxClassName={styles.checkBox}
											name="shipToIds"
											value={item.id}
											label={
												<div>
													<span>{item.name}</span>
													<div className={styles.shipToInfo}>
														<span>
															{translations?.basket.accountId}: {item.id}
														</span>
														<span>
															{item.streetAndNumber} {item.postalCode} {item.city}
														</span>
													</div>
												</div>
											}
											checked={isSelected}
										/>
									</li>
								);
							})
						) : (
							<li className={styles.noResults}>
								<span className={styles.noResultsHeading}>
									{searchText
										? formatTranslation(translations?.productList.noResultsFor, {
												0: searchText,
										  })
										: translations?.productList.noResults}
								</span>
								<p>{displayNoAccountsMessage()}</p>
							</li>
						)}
					</ul>
				</div>

				{selectedShipTos.length > 0 && (!parentRef || !isSmallScreenSize) && (
					<SelectedShipTos
						onToggleClick={onToggleClick}
						handleChange={handleChange}
						selectedShipTos={selectedShipTos}
						isExpanded={isSelectedShipTosExpanded}
					/>
				)}
			</form>
		</div>
	);
};

export default AccountSelectorBody;
