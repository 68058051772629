import { OrderLineResponse } from 'generated/data-contracts';

export const createSkeletonOrderLine = (): OrderLineResponse => {
	return {
		discount: '',
		markupPrice: 0,
		rrp: 0,
		wholeSalePrice: 0,
		basketId: 0,
		isFreeAssortment: false,
		bundleSize: 0,
		estimatedShippingDate: '',
		isDelivered: false,
		product: {
			brandName: '',
			productImages: [],
			masters: [],
			styleName: '',
			styleNumber: '',
			url: '',
		},
		trackAndTrace: [],
		status: '',
		platform: '',
		bundleQuantity: 0,
		orderLineQuantityPieces: 0,
		unitPrice: 0,
		orderLineNetPrice: '',
		warehouseShippingETA: '',
		orderNumber: '',
	};
};

export const skeletonOrderLines = (count = 4): OrderLineResponse[] => {
	const arr: OrderLineResponse[] = [];

	for (let i = 0; i < count; i++) {
		arr.push(createSkeletonOrderLine());
	}

	return arr;
};
